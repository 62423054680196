import React from "react";
import moment from "moment";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import StepOneReturnAddress from "./StepOneReturnAddress";
import StepTwoDeliveryAddress from "./StepTwoDeliveryAddress";
import StepThreeShippingDate from "./StepThreeShippingDate";
import StepFourPackageDetails from "./StepFourPackageDetails";
import StepFiveServiceType from "./StepFiveServiceType";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import BannerCarousel from "./../Banners/BannerCarousel";
import update from "immutability-helper";

class CreateLabel extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      configInfo: {},
      state: "",
      configLabelInfoisLoaded: false,
      fetchLabelInfoIsLoaded: false,
      fetchLabelInfo: {},
      batchNbr: "",
      cnsCartId: "",
      togglePSBannerExpand: false,
      hazmatYesNo: null,
      errors: {
        returnFirstName: "",
        returnLastName: "",
        returnCompany: "",
        returnStreetAddr: "",
        returnAptSuiteOther: "",
        returnCity: "",
        returnState: "",
        returnZipCode: "",
        returnEmail: "",
        trackingEmailNotifications: "",
        trackingTextNotifications: "",
        shipFromOtherZip: "",
        deliveryFirstName: "",
        deliveryLastName: "",
        deliveryCompany: "",
        deliveryStreetAddr: "",
        deliveryAptSuiteOther: "",
        deliveryAddress3: "",
        deliveryCity: "",
        deliveryState: "",
        deliveryZip: "",
        sendRecipientEmail: "",
        holdForPickupSearchNoZip: "",
        holdForPickupAddresseeEmail: "",
        holdForPickupAddresseePhone: "",
        holdForPickupMeEmail: "",
        holdForPickupMePhone: "",
        holdForPickupNoNotificationOption: "",
        shippingDate: "",
        pounds: "",
        ounces: "",
        length: "",
        width: "",
        height: "",
        girth: "",
        value: "",
        hazmatRadio: "",
        hazmat: "",
        intAddressField1: "",
        intCity: "",
        batchFirstName: "",
        batchLastName: "",
        batchCompany: "",
        batchStreetAddr: "",
        batchCity: "",
        batchState: "",
        batchZip: "",
        service: "",
        globalError: []
      },
      labelInd: {
        manifestInd: "",
        ptsNotifyInd: "",
        emailUpdatesInd: "",
        shipFromOtherZipInd: "",
        batchInd: "",
        shipNotifyInd: "",
        holdForPickupInd: "",
        // For clarity:
        // nonmachinableInd = "0" = "N" = is machinable
        // nonmachinableInd != "0" = "Y" = is not machinable
        nonmachinableInd: "0",
        giftInd: "",
        deliveryTimeOption: "",
        insuranceInd: "N",
        insRestrictedDeliveryInd: "",
        noWkndDlvyInd: "",
        nonDelvOption: "",
        ptsExpectedDelvInd: "",
        ptsDayOfDelvInd: "",
        ptsDeliveredToAddrInd: "",
        ptsPickupAvailableInd: "",
        ptsAlertsOtherInd: "",
        ptsPkgInTransitInd: "",
        flatRateInd: "",
        crematedRemainsInd: "N",
        sundayDeliveryInd: "N",
        stealthPostageInd: ""
      },
      returnAddress: {
        phoneId: "",
        faxId: "",
        emailId: "",
        addressId: "",
        contactId: "-1",
        countryId: "",
        addressCategory: "",
        firstName: "",
        middleInit: "",
        lastName: "",
        companyName: "",
        line1Addr: "",
        line2Addr: "",
        cityName: "",
        stateId: "",
        postalCode: "",
        urbanizationCode: "",
        emailAddress: "",
        phoneNbr: ""
      },

      deliveryAddress: {
        phoneId: "-1",
        faxId: "-1",
        emailId: "-1",
        addressId: "-1",
        contactId: "-1",
        poFlag: "",
        countryId: "",
        firstName: "",
        middleInit: "",
        lastName: "",
        companyName: "",
        line1Addr: "",
        line2Addr: "",
        line3Addr: "",
        province: "",
        cityName: "",
        stateId: "",
        postalCode: "",
        urbanizationCode: "",
        phoneNbr: "",
        refNbr: "",
        emailAddress: ""
      },
      shipFromPostalCode: "",
      cnsCartTypeId: "1",
      toNote: "",
      labelHfp: {
        holdForPickupZip: "",
        senderEmailType: "EMAIL",
        senderEmailText: "",
        recipEmailType: "EMAIL",
        recipEmailText: ""
      },
      shippingDate: new Date(),
      shippingDates: [],
      labelPkg: {
        largePackageInd: "",
        pkgLengthQty: "",
        pkgWidthQty: "",
        pkgHeightQty: "",
        pkgGirthQty: "",
        pkgValueAmt: "",
        pkgShape: "R"
      },
      productId: {
        productId: ""
      },

      domesticProductId: {
        productId: 0,
        productCode: "",
        productDesc: "",
        creationDate: 0,
        createdBy: "",
        lastUpdateDate: 0,
        lastUpdateBy: "",
        productClass: "",
        productType: "",
        productClassDesc: "",
        maxPackageItems: 30,
        labelDimensions: "8 1/2 X 11",
        labelPageMin: 1,
        labelPageMax: 5,
        labelPageThreshold: 5,
        onlineDiscount: 0,
        productDescShortName: "DOM",
        trackableInd: "Y",
        maxPackageValue: 0,
        optLock: 0,
        sortOrd: 0,
        productDescription: "",
        productDimensions: "",
        productCategory: 0
      },
      batch: [],
      inBatchEdit: false,
      // True if the item is being edited from the cart
      isFromCart: false,
      // True only if the item is being edited from the cart, but before its
      // delivery address, for example, has been modified
      isEditLabel: false,
      saveLabelInfoAdditionalData: {
        batchCount: 1,
        cnsCartId: "",
        eSOFAllowed: "",
        productId: "",
        saveDeliveryToAddressBook: false,
        saveReturnToAddressBook: false
      }
    };
  }

  componentDidMount() {
    this.props.toggleSpinner(true);
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.editItem
    ) {
      // Editing an existing label
      this.editingLabelCall();
    } else if (window.location.search.includes("deliveryContactId")) {
      // Prepopulate delivery address with contact from address book
      // (applies if user was on the GAB page, selected a contact, and
      // clicked "START A LABEL" to be redirected to CNS)
      const splitAddressBookContactId = window.location.search.split("=");
      this.preselectedContactId = splitAddressBookContactId[1];
      this.newLabelCall();
    } else {
      // Creating a new label
      this.newLabelCall();
    }
  }

  editingLabelCall = () => {
    this.setState({
      isFromCart: true,
      isEditLabel: true
    });
    Promise.all([
      axios.get(
        "/go/cnsrest/fetchLabelInfo?cnsCartId=" +
          this.props.history.location.state.editItem +
          "&mobileString=false"
      ),
      axios.get("/go/cnsrest/configLabelInfo")
    ])
      .then((response) => {
        // Fetch label info API call
        this.props.setShowUnenrolledParcelSelectBanner(
          response[0].data.cnsCartList[0].returnAddress
        );
        this.setState((prevState) => {
          return {
            cnsCartList: {
              ...response[0].data.cnsCartList[0]
            },
            returnAddress: {
              ...prevState.returnAddress,
              ...response[0].data.cnsCartList[0].returnAddress,
              line3Addr: "",
              labelInd: {
                ...response[0].data.cnsCartList[0].labelInd
              },
              stateId: String(
                response[0].data.cnsCartList[0].returnAddress.stateId
              )
            },
            fetchLabelInfoIsLoaded: true,
            checkNonmachinableInd: true,
            deliveryAddress:
              response[0].data.cnsCartList[0].deliveryAddress.stateId !==
              undefined
                ? {
                    ...prevState.deliveryAddress,
                    ...response[0].data.cnsCartList[0].deliveryAddress,
                    stateId: String(
                      response[0].data.cnsCartList[0].deliveryAddress.stateId
                    )
                  }
                : {
                    ...prevState.deliveryAddress,
                    ...response[0].data.cnsCartList[0].deliveryAddress
                  },
            shipFromPostalCode: response[0].data.cnsCartList[0]
              .shipFromPostalCode
              ? response[0].data.cnsCartList[0].shipFromPostalCode
              : "",
            shippingDate: response[0].data.cnsCartList[0].shippingDate
              ? moment(
                  moment(response[0].data.cnsCartList[0].shippingDate).format(
                    "YYYY-MM-DD"
                  )
                )
              : "",
            state: response[0].data.cnsCartList[0].state
              ? response[0].data.cnsCartList[0].state
              : "",
            toNote: response[0].data.cnsCartList[0].toNote
              ? response[0].data.cnsCartList[0].toNote
              : "",
            labelPkg: {
              ...prevState.labelPkg,
              ...response[0].data.cnsCartList[0].labelPkg,
              pkgHeightQty: String(
                response[0].data.cnsCartList[0].labelPkg.pkgHeightQty
              ),
              pkgLengthQty: String(
                response[0].data.cnsCartList[0].labelPkg.pkgLengthQty
              ),
              pkgWidthQty: String(
                response[0].data.cnsCartList[0].labelPkg.pkgWidthQty
              ),
              pkgGirthQty: String(
                response[0].data.cnsCartList[0].labelPkg.pkgGirthQty
              ),
              pkgShape: response[0].data.cnsCartList[0].labelPkg.pkgShape,
              weightOZQtyPound:
                response[0].data.cnsCartList[0].labelPkg.pounds > 0
                  ? response[0].data.cnsCartList[0].labelPkg.pounds
                  : "",
              weightOZQtyOunces:
                response[0].data.cnsCartList[0].labelPkg.ounces > 0
                  ? response[0].data.cnsCartList[0].labelPkg.ounces
                  : "",
              pkgValueAmt: response[0].data.cnsCartList[0].labelPkg.pkgValueAmt
            },
            saveLabelInfoAdditionalData: {
              ...prevState.saveLabelInfoAdditionalData,
              productId: response[0].data.cnsCartList[0].product.productId
            },
            labelInd: {
              ...prevState.labelInd,
              ...response[0].data.cnsCartList[0].labelInd,
              additionalService: response[0].data.cnsCartList[0].labelInd
                .additionalService
                ? response[0].data.cnsCartList[0].labelInd.additionalService
                    ._name
                : "",
              nonDelvOption: response[0].data.cnsCartList[0].labelInd
                .nonDelvOption
                ? response[0].data.cnsCartList[0].labelInd.nonDelvOption._name
                : "RETURN_TO_SENDER",
              emailUpdatesInd: prevState.labelInd.ptsTrackingEmail ? "Y" : "N",
              ptsTxtUpdatesInd: prevState.labelInd.ptsTxtPhoneNbr ? "Y" : "N"
            },
            labelHfp: {
              ...prevState.labelHfp,
              ...response[0].data.cnsCartList[0].labelHfp
            },
            eSOFAllowed: response[0].data.cnsCartList[0].eSOFAllowed,
            hazmatYesNo:
              response[0].data.cnsCartList[0].hazmatType !== undefined &&
              response[0].data.cnsCartList[0].hazmatType !== "0"
                ? true
                : false,
            hazmatType: response[0].data.cnsCartList[0].hazmatType
          };
        });

        // Config label info API call
        this.setState((prevState) => {
          return {
            configLabelInfoisLoaded: true,
            // labelInd: {
            //   ...prevState.labelInd
            //   // nonDelvOption: "RETURN_TO_SENDER",
            //   //   response[1].data.preferences.ptsTrackingEmail,
            //   // ptsTxtPhoneNbr:
            //   //   response[1].data.preferences.ptsTxtPhoneNbr
            // },
            domesticProductId: {
              ...prevState.domesticProductId,
              ...response[1].data.preferences.domesticProductId
            },
            uspsConnectRadius:
              response[1].data.preferences.uspsConnectRadius || null,

            shippingDates: {
              ...response[1].data.shippingDates
            },
            countryList: this.reorderCountryList(
              response[1].data.countryZonesViewBean
            ),
            hidePostagePriceAddressIds:
              response[1].data.hidePostagePriceAddressIds
          };
        });
        this.props.setUserPreferences(response[1].data.preferences);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  newLabelCall = () => {
    Promise.all([
      axios.get(
        "/go/cnsrest/fetchLabelInfo?cnsCartId=0&mobileString=false" +
          (this.preselectedContactId
            ? "&deliveryContactIdParam=" + this.preselectedContactId
            : "")
      ),
      axios.get("/go/cnsrest/configLabelInfo")
    ])
      .then((response) => {
        // Fetch label info API call
        this.props.setShowUnenrolledParcelSelectBanner(
          response[0].data.cnsCartList[0].returnAddress
        );
        this.setState((prevState) => {
          return {
            cnsCartList: {
              ...response[0].data.cnsCartList[0]
            },
            returnAddress: {
              ...prevState.returnAddress,
              ...response[0].data.cnsCartList[0].returnAddress,
              line3Addr: "",
              labelInd: {
                ...response[0].data.cnsCartList[0].labelInd
              }
            },
            fetchLabelInfoIsLoaded: true,
            shipFromPostalCode: response[0].data.cnsCartList[0]
              .shipFromPostalCode
              ? response[0].data.cnsCartList[0].shipFromPostalCode
              : "",
            deliveryAddress: {
              ...prevState.deliveryAddress,
              ...response[0].data.cnsCartList[0].deliveryAddress
            }
          };
        });

        // Config label info API call
        this.setState((prevState) => {
          let labelInd = {
            ...response[1].data.preferences
          };
          delete labelInd.stealthPostageCLInd;
          delete labelInd.stealthPostageCLMInd;
          delete labelInd.stealthPostageFCPSInd;
          delete labelInd.stealthPostagePMEInd;
          delete labelInd.stealthPostagePMInd;
          delete labelInd.stealthPostagePSGInd;
          delete labelInd.stealthPostageRGInd;

          // This radius is needed elsewhere, not in labelInd
          delete labelInd.uspsConnectRadius;

          return {
            focusOnTop: true,
            configLabelInfoisLoaded: true,
            domesticProductId: {
              ...prevState.domesticProductId,
              ...response[1].data.preferences.domesticProductId
            },
            labelInd: labelInd,
            labelPkg: {
              ...prevState.labelPkg,
              largePackageInd:
                response[1].data.preferences.largePackageInd || "N",
              pkgHeightQty: String(
                response[1].data.preferences.pkgHeightQty || ""
              ),
              pkgLengthQty: String(
                response[1].data.preferences.pkgLengthQty || ""
              ),
              pkgWidthQty: String(
                response[1].data.preferences.pkgWidthQty || ""
              ),
              pkgGirthQty: String(
                response[1].data.preferences.pkgGirthQty || ""
              ),
              pkgShape: response[1].data.preferences.pkgShape || "R",
              weightOZQtyPound:
                response[1].data.preferences.pounds > 0
                  ? response[1].data.preferences.pounds
                  : "",
              weightOZQtyOunces:
                response[1].data.preferences.ounces > 0
                  ? response[1].data.preferences.ounces
                  : ""
            },
            uspsConnectRadius:
              response[1].data.preferences.uspsConnectRadius || null,
            shippingDates: {
              ...response[1].data.shippingDates
            },
            countryList: this.reorderCountryList(
              response[1].data.countryZonesViewBean
            ),
            hidePostagePriceAddressIds:
              response[1].data.hidePostagePriceAddressIds
          };
        });
        this.props.setUserPreferences(response[1].data.preferences);

        this.props.toggleSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    // Focus on the top element on the page after loading (only for new labels)
    if (
      this.state.focusOnTop &&
      this.state.configLabelInfoisLoaded &&
      this.state.fetchLabelInfoIsLoaded
    ) {
      document.getElementById("return-address").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // When editing a label from the cart, if the user had previously entered
    // weights/dimensions that resulted in the nonmachinable indicator being set
    // without being given the option to choose from the dropdown
    // (i.e. the dropdown was hidden), reset the nonmachinable dropdown to the
    // "None" option (this will automatically be modified if necessary when
    // saving this field).
    if (this.state.checkNonmachinableInd) {
      if (
        this.state.labelInd &&
        this.state.labelInd.nonmachinableInd &&
        this.hideNonmachinable()
      ) {
        this.setState((prevState) => {
          return {
            labelInd: {
              ...prevState.labelInd,
              nonmachinableInd: "0"
            }
          };
        });
      }
      this.setState({
        checkNonmachinableInd: false
      });
    }
    // When in the USPS Connect Local flow, check that a relevant field
    // has actually been changed, as opposed to just tabbing past the field,
    // for instance
    if (
      // Changes to return address
      // This block looks for changes to return address fields only
      // when the ship from another ZIP code option has not been selected
      (!this.state.shipFromPostalCode &&
        (this.state.returnAddress.line1Addr !==
          prevState.returnAddress.line1Addr ||
          this.state.returnAddress.line2Addr !==
            prevState.returnAddress.line2Addr ||
          this.state.returnAddress.cityName !==
            prevState.returnAddress.cityName ||
          this.state.returnAddress.stateId !==
            prevState.returnAddress.stateId ||
          this.state.returnAddress.postalCode !==
            prevState.returnAddress.postalCode)) ||
      // This looks for changes to the ship from another ZIP code option
      // (if so, then that is sufficient; we don't need to check other
      // return address fields)
      this.state.shipFromPostalCode !== prevState.shipFromPostalCode ||
      // Changes to delivery address
      this.state.deliveryAddress.countryId !==
        prevState.deliveryAddress.countryId ||
      this.state.deliveryAddress.postalCode !==
        prevState.deliveryAddress.postalCode
    ) {
      this.setState({
        hasDropOffInputFieldChanged: true
      });
    }
    // Check for drop-off locations if the user has just enrolled in
    // USPS Connect
    if (this.props.checkForDropOffLocationsNow) {
      this.props.setCheckForDropOffLocationsNow(false);
      this.fetchDropOffLocationsIfNeeded();
    }
  }

  isDomesticDestination = () => {
    return (
      this.state.deliveryAddress.countryId === 840 ||
      this.state.deliveryAddress.countryId === "840"
    );
  };

  setIsEditLabel = (newValue) => {
    this.setState({
      isEditLabel: newValue
    });
  };

  // Move the United States to the top of the list
  reorderCountryList = (originalCountryList) => {
    let countryList = JSON.parse(JSON.stringify(originalCountryList));
    let unitedStates = [];
    let unitedStatesIndex = -1;
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        unitedStates.push(countryList[i]);
        unitedStatesIndex = i;
        break;
      }
    }
    countryList.splice(unitedStatesIndex, 1);
    countryList = unitedStates.concat(countryList);
    return countryList;
  };

  updateUSAState = (USAState) => {
    this.setState({
      returnAddress: {
        ...this.state.returnAddress,
        stateId: USAState
      }
    });
  };

  changeReturnAddressField = (input) => (event) => {
    if (input === "GABReturn") {
      // Clear any existing errors for the fields provided from the address book
      if (event.firstName) {
        this.clearError("returnFirstName");
      }
      if (event.lastName) {
        this.clearError("returnLastName");
      }
      if (event.firstName && event.lastName) {
        this.clearError("returnCompany");
      } else if (event.companyName) {
        this.clearError("returnFirstName");
        this.clearError("returnLastName");
        this.clearError("returnCompany");
      }
      const address = event.address;
      if (address) {
        if (address.addressLine1) {
          this.clearError("returnStreetAddr");
        }
        // Since this field is optional, remove the error
        this.clearError("returnAptSuiteOther");
        if (address.city) {
          this.clearError("returnCity");
        }
        if (address.state && address.state.stateDbId) {
          this.clearError("returnState");
        }
        if (address.zipCode || address.postalCode) {
          this.clearError("returnZipCode");
        }

        // Check for a drop-off location if eligible for USPS Connect local
        let enteredReturnZip = "";
        let usingOtherZip = false;
        if (
          this.state.labelInd &&
          this.state.labelInd.shipFromOtherZipInd === "Y"
        ) {
          enteredReturnZip = this.state.shipFromPostalCode;
          usingOtherZip = true;
        } else {
          if (address.zipCode) {
            enteredReturnZip = address.zipCode;
          } else if (address.postalCode) {
            enteredReturnZip = address.postalCode;
          }
        }
        if (
          // Using the return address ZIP code
          !usingOtherZip &&
          // Parcel Select flag
          this.props.userData &&
          this.props.userData.parcelSelectFlag &&
          // Required return address fields
          address.addressLine1 &&
          address.city &&
          address.state &&
          address.state.stateDbId > 0 &&
          String(address.state.stateDbId) !== "2" &&
          String(address.state.stateDbId) !== "3" &&
          String(address.state.stateDbId) !== "6" &&
          enteredReturnZip &&
          enteredReturnZip.length >= 5 &&
          // Required delivery address inputs
          this.isDomesticDestination() &&
          this.state.deliveryAddress.postalCode &&
          this.state.deliveryAddress.postalCode.length >= 5
        ) {
          this.testDropOff(
            // Required and optional return address fields
            address.addressLine1,
            address.addressLine2,
            address.city,
            address.state.stateDbId,
            enteredReturnZip.slice(0, 5),
            enteredReturnZip.replaceAll("-", "").slice(5),
            // Required delivery address inputs
            this.state.deliveryAddress.postalCode.slice(0, 5)
          );
        } else if (
          // Using the ship from another ZIP code option
          usingOtherZip &&
          // Parcel Select flag
          this.props.userData &&
          this.props.userData.parcelSelectFlag &&
          // Required return address fields
          enteredReturnZip &&
          enteredReturnZip.length >= 5 &&
          // Required delivery address inputs
          this.isDomesticDestination() &&
          this.state.deliveryAddress.postalCode &&
          this.state.deliveryAddress.postalCode.length >= 5
        ) {
          this.testDropOffWithOtherZip(
            // Required and optional return address fields
            enteredReturnZip.slice(0, 5),
            enteredReturnZip.replaceAll("-", "").slice(5),
            // Required delivery address inputs
            this.state.deliveryAddress.postalCode.slice(0, 5)
          );
        } else {
          this.setState({
            dropOffLocations: [],
            selectedDropOffLocationIndex: ""
          });
        }

        // Update the fields provided from the address book
        this.setState((prevState) => {
          return {
            returnAddress: {
              ...prevState.returnAddress,
              firstName: event.firstName || "",
              middleInit: event.middleName || "",
              lastName: event.lastName || "",
              companyName: event.companyName || "",
              line1Addr: address.addressLine1 || "",
              line2Addr: address.addressLine2 || "",
              cityName: address.city || "",
              stateId:
                address.state && address.state.stateDbId
                  ? String(address.state.stateDbId)
                  : "",
              postalCode: address.zipCode || address.postalCode || "",
              urbanizationCode: address.urbanizationCode || "",
              emailAddress: event.emailAddress || ""
            }
          };
        });
      }
    } else {
      if (event && event.target) {
        // When "event" is the entire event object
        const { value, maxLength } = event.target;
        this.setState({
          returnAddress: {
            ...this.state.returnAddress,
            [input]: value.slice(0, maxLength)
          }
        });
      } else {
        // When "event" is the event value
        this.setState({
          returnAddress: {
            ...this.state.returnAddress,
            [input]: event
          }
        });
      }
    }
  };

  changeTrackingNotifications = (input) => (event) => {
    if (input === "allNotificationsUpdate") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            ptsExpectedDelvInd: "Y",
            ptsDayOfDelvInd: "Y",
            ptsDeliveredToAddrInd: "Y",
            ptsPickupAvailableInd: "Y",
            ptsAlertsOtherInd: "Y",
            ptsPkgInTransitInd: "Y"
          }
        };
      });
    } else if (input === "insRestrictedDeliveryInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            insRestrictedDeliveryInd: "Y",
            insuranceInd: "N",
            additionalService: ""
          },
          eSOFAllowed: "N"
        };
      });
    } else if (input === "insuranceInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            insRestrictedDeliveryInd: "N",
            insuranceInd: event
          }
        };
      });
    } else if (input === "setFlatRateInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            flatRateInd: event,
            pkgGirthQty: "",
            pkgHeightQty: "",
            pkgLengthQty: "",
            pkgShape: "R",
            pkgWidthQty: ""
          },

          labelPkg: {
            ...prevState.labelPkg,
            largePackageInd: "N",
            pkgGirthQty: "",
            pkgHeightQty: "",
            pkgLengthQty: "",
            pkgShape: "R",
            pkgWidthQty: ""
          }
        };
      });
    } else if (input === "additionalService") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            additionalService: event
          },
          // Checked = Y = Do not allow electronic signature
          // Unchecked = N = Allow electronic signature
          // (this is to keep it consistent with the backend code)
          eSOFAllowed:
            ((event === "SIG" || event === "DEL" || event === "SIGRQ") &&
              prevState.eSOFAllowed === "Y") ||
            (event === "DEL" && !prevState.eSOFAllowed) ||
            (event === "SIGRQ" && !prevState.eSOFAllowed)
              ? "Y"
              : "N"
        };
      });
    } else if (input === "deliveryTimeOption") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            deliveryTimeOption: event
          }
        };
      });
    } else if (input === "noWkndDlvyInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            noWkndDlvyInd: event ? "Y" : "N"
          }
        };
      });
    } else if (input === "crematedRemainsInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            crematedRemainsInd: event ? "Y" : "N",
            additionalService:
              prevState.labelInd.crematedRemainsInd === "N" && event === true
                ? "SIGRQ"
                : prevState.labelInd.additionalService
          },
          eSOFAllowed:
            event && prevState.labelInd.additionalService !== "SIGRQ"
              ? "N"
              : prevState.eSOFAllowed
        };
      });
    } else if (input === "sundayDeliveryInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            sundayDeliveryInd: event ? "Y" : "N"
          }
        };
      });
    } else if (input === "shipFromPostalCode") {
      if (event && event.target) {
        // When "event" is the entire event object
        const { value, maxLength } = event.target;
        this.setState((prevState) => {
          return {
            labelInd: {
              ...prevState.labelInd,
              [input]: value.slice(0, maxLength)
            },
            shipFromPostalCode: value.slice(0, maxLength)
          };
        });
      } else {
        // When "event" is the event value
        this.setState((prevState) => {
          return {
            labelInd: {
              ...prevState.labelInd,
              [input]: event
            },
            shipFromPostalCode: event
          };
        });
      }
    } else if (input === "giftInd") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            [input]: event ? "Y" : "N"
          }
        };
      });
    } else if (input === "nonDelvOption") {
      this.setState((prevState) => {
        return {
          labelInd: {
            ...prevState.labelInd,
            [input]: event
          }
        };
      });
    } else {
      if (event && event.target) {
        // When "event" is the entire event object
        const { value, maxLength } = event.target;
        this.setState((prevState) => {
          return {
            labelInd: {
              ...prevState.labelInd,
              [input]: value.slice(0, maxLength)
            }
          };
        });
      } else {
        // When "event" is the event value
        this.setState((prevState) => {
          return {
            labelInd: {
              ...prevState.labelInd,
              [input]: event
            }
          };
        });
      }
    }
  };

  changeNonNestedFields = (input) => (event) => {
    if (input === "batch") {
      let joined = this.state.batch.concat(event);
      this.setState({
        batch: joined
      });
    } else if (input === "shippingDate") {
      this.setState((prevState) => {
        return {
          ...prevState,
          [input]:
            event || moment(moment(prevState.shippingDate).format("YYYY-MM-DD"))
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          [input]: event
        };
      });
    }
  };

  changeDeliveryAddress = (input) => (event) => {
    if (input === "GABDelivery") {
      // Clear any existing errors for the fields provided from the address book
      if (event.firstName) {
        this.clearError("deliveryFirstName");
      }
      if (event.lastName) {
        this.clearError("deliveryLastName");
      }
      if (event.firstName && event.lastName) {
        this.clearError("deliveryCompany");
      } else if (event.companyName) {
        this.clearError("deliveryFirstName");
        this.clearError("deliveryLastName");
        this.clearError("deliveryCompany");
      }
      const address = event.address;
      if (address) {
        if (address.addressLine1) {
          this.clearError("deliveryStreetAddr");
        }
        // Since these fields are optional, remove the errors
        this.clearError("deliveryAptSuiteOther");
        this.clearError("deliveryAddress3");
        if (address.city) {
          this.clearError("deliveryCity");
        }
        if (address.state && address.state.stateDbId) {
          this.clearError("deliveryState");
        }
        if (address.zipCode || address.postalCode) {
          this.clearError("deliveryZip");
        }

        // Check for a drop-off location if eligible for USPS Connect local
        let enteredReturnZip = "";
        let usingOtherZip = false;
        if (
          this.state.labelInd &&
          this.state.labelInd.shipFromOtherZipInd === "Y"
        ) {
          enteredReturnZip = this.state.shipFromPostalCode;
          usingOtherZip = true;
        } else {
          enteredReturnZip = this.state.returnAddress.postalCode;
        }
        let enteredDeliveryZip = "";
        if (address.zipCode) {
          enteredDeliveryZip = address.zipCode;
        } else if (address.postalCode) {
          enteredDeliveryZip = address.postalCode;
        }
        if (
          // Using the return address ZIP code
          !usingOtherZip &&
          // Parcel Select flag
          this.props.userData &&
          this.props.userData.parcelSelectFlag &&
          // Required return address fields
          this.state.returnAddress.line1Addr &&
          this.state.returnAddress.cityName &&
          this.state.returnAddress.stateId > 0 &&
          String(this.state.returnAddress.stateId) !== "2" &&
          String(this.state.returnAddress.stateId) !== "3" &&
          String(this.state.returnAddress.stateId) !== "6" &&
          enteredReturnZip &&
          enteredReturnZip.length >= 5 &&
          // Required delivery address inputs
          address.country &&
          address.country.countryId === "840" &&
          enteredDeliveryZip &&
          enteredDeliveryZip.length >= 5
        ) {
          this.testDropOff(
            // Required and optional return address fields
            this.state.returnAddress.line1Addr,
            this.state.returnAddress.line2Addr,
            this.state.returnAddress.cityName,
            this.state.returnAddress.stateId,
            enteredReturnZip.slice(0, 5),
            enteredReturnZip.replaceAll("-", "").slice(5),
            // Required delivery address fields
            enteredDeliveryZip.slice(0, 5)
          );
        } else if (
          // Using the ship from another ZIP code option
          usingOtherZip &&
          // Parcel Select flag
          this.props.userData &&
          this.props.userData.parcelSelectFlag &&
          // Required return address fields
          enteredReturnZip &&
          enteredReturnZip.length >= 5 &&
          // Required delivery address inputs
          address.country &&
          address.country.countryId === "840" &&
          enteredDeliveryZip &&
          enteredDeliveryZip.length >= 5
        ) {
          this.testDropOffWithOtherZip(
            // Required and optional return address fields
            enteredReturnZip.slice(0, 5),
            enteredReturnZip.replaceAll("-", "").slice(5),
            // Required delivery address fields
            enteredDeliveryZip.slice(0, 5)
          );
        } else {
          this.setState({
            dropOffLocations: [],
            selectedDropOffLocationIndex: ""
          });
        }

        // Update the fields provided from the address book
        if (address.country) {
          if (address.country.countryId === "840") {
            // Domestic address
            this.setState((prevState) => {
              return {
                deliveryAddress: {
                  // Update domestic fields
                  ...prevState.deliveryAddress,
                  contactId: String(event.contactId),
                  countryId: parseInt(address.country.countryId, 10),
                  countryName: address.country.countryName,
                  firstName: event.firstName || "",
                  middleInit: event.middleName || "",
                  lastName: event.lastName || "",
                  companyName: event.companyName || "",
                  line1Addr: address.addressLine1 || "",
                  line2Addr: address.addressLine2 || "",
                  cityName: address.city || "",
                  stateId:
                    address.state && address.state.stateDbId
                      ? String(address.state.stateDbId)
                      : "",
                  postalCode: address.zipCode || address.postalCode || "",
                  urbanizationCode: address.urbanizationCode || "",
                  refNbr: address.referenceNumber || "",
                  emailAddress: event.emailAddress,
                  // Reset international-only fields
                  province: "",
                  phoneNbr: ""
                }
              };
            });
          } else {
            // International address
            this.setState((prevState) => {
              return {
                deliveryAddress: {
                  // Update international fields
                  ...prevState.deliveryAddress,
                  countryId: parseInt(address.country.countryId, 10),
                  countryName: address.country.countryName,
                  firstName: event.firstName || "",
                  middleInit: event.middleName || "",
                  lastName: event.lastName || "",
                  companyName: event.companyName || "",
                  line1Addr: address.addressLine1 || "",
                  line2Addr: address.addressLine2 || "",
                  line3Addr: address.addressLine3 || "",
                  province: address.province || "",
                  cityName: address.city || "",
                  postalCode: address.postalCode || "",
                  phoneNbr: event.phoneNbr || "",
                  refNbr: address.referenceNumber || "",
                  emailAddress: event.emailAddress,
                  // Reset domestic-only fields
                  stateId: "",
                  urbanizationCode: ""
                }
              };
            });
          }
        }
      }
    } else if (input === "switchFromBatch") {
      // Copy the relevant batch fields to the corresponding non-batch fields
      // (here, "event" is the entire state after copying over the batch fields)
      this.setState({
        ...event
      });
    } else {
      if (event && event.target) {
        // When "event" is the entire event object
        const { value, maxLength } = event.target;
        this.setState({
          deliveryAddress: {
            ...this.state.deliveryAddress,
            [input]: value.slice(0, maxLength)
          }
        });
      } else if (input === "stateIdDeliveryAddressChangeCountry") {
        this.setState({
          deliveryAddress: {
            ...this.state.deliveryAddress,
            countryName: event[1],
            countryId: event[0]
          }
        });
      } else {
        // When "event" is the event value
        this.setState({
          deliveryAddress: {
            ...this.state.deliveryAddress,
            [input]: event
          }
        });
      }
    }
  };

  // Check if any of the provided contact IDs should have the postage price
  // hidden
  setUsingHidePostagePriceAddress = (cnsCartList) => {
    this.setState((prevState) => {
      let usingHidePostagePriceAddress = false;
      for (let i in cnsCartList) {
        usingHidePostagePriceAddress = prevState.hidePostagePriceAddressIds.includes(
          cnsCartList[i].deliveryAddress.contactId
        );
        if (usingHidePostagePriceAddress) {
          break;
        }
      }
      return {
        usingHidePostagePriceAddress: usingHidePostagePriceAddress
      };
    });
  };

  testDropOffWithOtherZip = (
    enteredReturnZip5,
    enteredReturnZipPlus4,
    enteredDeliveryZip
  ) => {
    this.testDropOff(
      undefined,
      undefined,
      undefined,
      undefined,
      enteredReturnZip5,
      enteredReturnZipPlus4,
      enteredDeliveryZip
    );
  };

  testDropOff = (
    enteredReturnLine1Addr,
    enteredReturnLine2Addr,
    enteredReturnCity,
    enteredReturnStateId,
    enteredReturnZip5,
    enteredReturnZipPlus4,
    enteredDeliveryZip
  ) => {
    const dataToSend = {
      returnLine1Addr: enteredReturnLine1Addr,
      returnLine2Addr: enteredReturnLine2Addr,
      returnCity: enteredReturnCity,
      returnStateId: enteredReturnStateId,
      returnZip5: enteredReturnZip5,
      returnZipPlus4: enteredReturnZipPlus4,
      deliveryZip5: enteredDeliveryZip,
      radius: this.state.uspsConnectRadius,
      mapSource: "createLabel"
    };
    axios
      .post("/go/cnsrest/fetchDropOffLocations", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        let dropoffLocationData = {
          locationList: []
        };
        if (
          response.data &&
          response.data.dropoffLocationData &&
          response.data.dropoffLocationData.successful &&
          response.data.dropoffLocationData.locationList &&
          Array.isArray(response.data.dropoffLocationData.locationList)
        ) {
          dropoffLocationData = response.data.dropoffLocationData;
        }
        this.updateDropOffLocation(dropoffLocationData, enteredDeliveryZip);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setBatchChecked = (checked) => {
    this.setState({
      batchChecked: checked
    });
  };

  setAddToBatchMessage = (value) => {
    this.setState({
      addToBatchMessage: value
        ? 'Please click "Add to Batch" to add this item to the batch order'
        : ""
    });
  };

  changeHoldForPickup = (input) => (event) => {
    if (input === "clearSender") {
      this.setState({
        labelHfp: {
          ...this.state.labelHfp,
          senderCheckbox: false,
          senderEmailType: "EMAIL",
          senderEmailText: "",
          senderPhoneText: ""
        }
      });
    } else if (input === "clearAddressee") {
      this.setState({
        labelHfp: {
          ...this.state.labelHfp,
          recipCheckbox: false,
          recipEmailType: "EMAIL",
          recipEmailText: "",
          recipPhoneText: ""
        }
      });
    } else {
      if (event && event.target) {
        // When "event" is the entire event object
        const { value, maxLength } = event.target;
        this.setState({
          labelHfp: {
            ...this.state.labelHfp,
            [input]: value.slice(0, maxLength)
          }
        });
      } else {
        // When "event" is the event value
        this.setState({
          labelHfp: {
            ...this.state.labelHfp,
            [input]: event
          }
        });
      }
    }
  };

  setInBatchEdit = (value) => {
    this.setState({
      inBatchEdit: value
    });
    if (value) {
      this.setFocusOnBatchEdit(true);
    }
  };

  setFocusOnBatchEdit = (value) => {
    this.setState({
      focusOnBatchEdit: value
    });
  };

  removeBatchItem = (e, i) => {
    e.preventDefault();
    this.setState((state) => {
      const batch = state.batch.filter((item, j) => i !== j);
      return {
        batch
      };
    });
    this.setFocusOnBatchEmpty(true);
  };

  removeAllBatch = () => {
    this.setState({
      batch: []
    });
    this.setFocusOnBatchEmpty(true);
  };

  setFocusOnBatchEmpty = (value) => {
    this.setState({
      focusOnBatchEmpty: value
    });
  };

  changelabelPkg = (input) => (event) => {
    if (
      input === "pkgLengthQty" ||
      input === "pkgWidthQty" ||
      input === "pkgHeightQty" ||
      input === "pkgGirthQty"
    ) {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          [input]: event
        },
        labelInd: {
          ...this.state.labelInd,
          [input]: event
        }
      });
    } else if (input === "weightOZQtyPound") {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          [input]: event
        },
        labelInd: {
          ...this.state.labelInd,
          pounds: event
        }
      });
    } else if (input === "weightOZQtyOunces") {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          [input]: event
        },
        labelInd: {
          ...this.state.labelInd,
          ounces: event
        }
      });
    } else if (input === "resetPackageDim") {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          largePackageInd: "N",
          pkgGirthQty: "",
          pkgHeightQty: "",
          pkgLengthQty: "",
          pkgShape: "R",
          pkgWidthQty: ""
        },
        labelInd: {
          ...this.state.labelInd,
          largePackageInd: "N",
          pkgGirthQty: "",
          pkgHeightQty: "",
          pkgLengthQty: "",
          pkgShape: "R",
          pkgWidthQty: ""
        }
      });
    } else if (input === "resetGirth") {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          pkgGirthQty: "",
          pkgShape: "R"
        },
        labelInd: {
          ...this.state.labelInd,
          pkgGirthQty: "",
          pkgShape: "R"
        }
      });
    } else {
      this.setState({
        labelPkg: {
          ...this.state.labelPkg,
          [input]: event
        }
      });
    }
  };

  setPackageTypeProductId = (input) => {
    this.setState({
      saveLabelInfoAdditionalData: {
        ...this.state.saveLabelInfoAdditionalData,
        productId: input
      }
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      if (
        prevState.errors.step2 &&
        "" !== fieldName &&
        ("deliveryFirstName" === fieldName ||
          "deliveryLastName" === fieldName ||
          "deliveryCompany" === fieldName ||
          "deliveryStreetAddr" === fieldName ||
          "deliveryAptSuiteOther" === fieldName ||
          "deliveryAddress3" === fieldName ||
          "deliveryCity" === fieldName ||
          "deliveryState" === fieldName ||
          "deliveryZip" === fieldName)
      ) {
        // If any field with an error in step 2 is being edited,
        // clear the step 2 message as well as the field provided
        return {
          errors: {
            ...prevState.errors,
            [fieldName]: "",
            step2: ""
          }
        };
      } else {
        // Clear only the field provided
        return {
          errors: {
            ...prevState.errors,
            [fieldName]: ""
          }
        };
      }
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  isWeightRequired = () => {
    return (
      this.state.labelInd.flatRateInd === "N" ||
      (this.state.deliveryAddress.countryId &&
        this.state.deliveryAddress.countryId !== "840" &&
        this.state.deliveryAddress.countryId !== 840)
    );
  };

  // The user is in the USPS Connect flow if all of the following are true:
  // - the parcel select flag is set
  // - the destination is domestic
  // - the return address is not an APO/DPO/FPO address
  //   (i.e. check that this is not RG)
  //   OR the user is shipping from another zip code
  isInUspsConnectFlow = () => {
    return (
      this.props.userData.parcelSelectFlag &&
      this.isDomesticDestination() &&
      ((this.state.returnAddress.stateId !== "2" &&
        this.state.returnAddress.stateId !== "3" &&
        this.state.returnAddress.stateId !== "6") ||
        this.state.labelInd.shipFromOtherZipInd === "Y")
    );
  };

  fetchDropOffLocationsIfNeeded = () => {
    // Check that a relevant field has actually been changed,
    // as opposed to just tabbing past the field, for instance
    if (this.state.hasDropOffInputFieldChanged) {
      // Determine which return address ZIP code to use
      let enteredReturnZip = "";
      let usingOtherZip = false;
      if (
        this.state.labelInd &&
        this.state.labelInd.shipFromOtherZipInd === "Y"
      ) {
        enteredReturnZip = this.state.shipFromPostalCode;
        usingOtherZip = true;
      } else {
        enteredReturnZip = this.state.returnAddress.postalCode;
      }
      // Call the drop-off API only if certain inputs have been provided
      if (
        // Using the return address ZIP code
        !usingOtherZip &&
        // Check user's eligibility for the USPS Connect Local flow
        this.isInUspsConnectFlow() &&
        // Required return address fields
        this.state.returnAddress.line1Addr &&
        this.state.returnAddress.cityName &&
        this.state.returnAddress.stateId > 0 &&
        enteredReturnZip &&
        enteredReturnZip.length >= 5 &&
        // Required delivery address fields
        this.state.deliveryAddress.postalCode &&
        this.state.deliveryAddress.postalCode.length >= 5
      ) {
        this.testDropOff(
          // Required and optional return address fields
          this.state.returnAddress.line1Addr,
          this.state.returnAddress.line2Addr,
          this.state.returnAddress.cityName,
          this.state.returnAddress.stateId,
          enteredReturnZip.slice(0, 5),
          enteredReturnZip.replaceAll("-", "").slice(5),
          // Required delivery address fields
          this.state.deliveryAddress.postalCode.slice(0, 5)
        );
      } else if (
        // Using the ship from another ZIP code option
        usingOtherZip &&
        // Check user's eligibility for the USPS Connect Local flow
        this.isInUspsConnectFlow() &&
        // Required return address fields
        enteredReturnZip &&
        enteredReturnZip.length >= 5 &&
        // Required delivery address fields
        this.state.deliveryAddress.postalCode &&
        this.state.deliveryAddress.postalCode.length >= 5
      ) {
        this.testDropOffWithOtherZip(
          // Required and optional return address fields
          enteredReturnZip.slice(0, 5),
          enteredReturnZip.replaceAll("-", "").slice(5),
          // Required delivery address fields
          this.state.deliveryAddress.postalCode.slice(0, 5)
        );
      } else {
        // Reset the drop-off locations if the expected inputs have not
        // been provided
        this.setState({
          dropOffLocations: [],
          selectedDropOffLocationIndex: ""
        });
      }
      // Wait until another relevant field has been changed before entering here
      // again
      this.setState({
        hasDropOffInputFieldChanged: false
      });
    }
  };

  updateDropOffLocation = (dropoffLocationData, enteredDeliveryZip) => {
    let selectedDropOffLocationIndex = "";
    let zipServedFound = false;
    for (let i = 0; i < dropoffLocationData.locationList.length; i++) {
      if (!zipServedFound) {
        if (dropoffLocationData.locationList[i].zipsServed) {
          for (
            let j = 0;
            j < dropoffLocationData.locationList[i].zipsServed.length;
            j++
          ) {
            if (
              enteredDeliveryZip ===
              dropoffLocationData.locationList[i].zipsServed[j]
            ) {
              selectedDropOffLocationIndex = String(i);
              zipServedFound = true;
              break;
            }
          }
        }
      } else {
        break;
      }
    }
    this.setState({
      dropOffLocations: dropoffLocationData.locationList,
      selectedDropOffLocationIndex: selectedDropOffLocationIndex,
      dropoffLocationData: dropoffLocationData
    });
  };

  // If the nonmachinable dropdown is hidden:
  //   - and is not flat rate, it is nonmachinable.
  //   - and is flat rate, it is machinable.
  // If the nonmachinable dropdown is shown:
  //   - and an option other than "None" is selected, it is nonmachinable.
  //   - and the "None" option is selected, it is machinable.
  hideNonmachinable = () => {
    // If flat rate, hide the nonmachinable section
    if (this.state.labelInd && this.state.labelInd.flatRateInd === "Y") {
      return true;
    }
    // If not flat rate:
    const weightLbs = this.state.labelPkg.weightOZQtyPound
      ? Number(this.state.labelPkg.weightOZQtyPound)
      : 0;
    const weightOz = this.state.labelPkg.weightOZQtyOunces
      ? Number(this.state.labelPkg.weightOZQtyOunces)
      : 0;
    const totalWeightInOz = weightLbs * 16 + weightOz;
    const length =
      this.state.labelPkg.largePackageInd === "Y" &&
      this.state.labelPkg.pkgLengthQty
        ? Number(this.state.labelPkg.pkgLengthQty)
        : 0;
    const width =
      this.state.labelPkg.largePackageInd === "Y" &&
      this.state.labelPkg.pkgWidthQty
        ? Number(this.state.labelPkg.pkgWidthQty)
        : 0;
    const height =
      this.state.labelPkg.largePackageInd === "Y" &&
      this.state.labelPkg.pkgHeightQty
        ? Number(this.state.labelPkg.pkgHeightQty)
        : 0;
    // Hide the nonmachinable section if any of the following are true
    return (
      // - Weight is less than 6 oz. or greater than 560 oz. (35 lbs.)
      totalWeightInOz < 6 ||
      totalWeightInOz > 560 ||
      // - The large package indicator is set
      (this.state.labelPkg.largePackageInd === "Y" &&
        // and any of the following
        // - Length is less than 6 in. or greater than 27 in.
        // - Width is less than 0.25 in. or greater than 17 in.
        // - Height is less than 3 in. or greater than 17 in.
        (length < 6 ||
          length > 27 ||
          width < 0.25 ||
          width > 17 ||
          height < 3 ||
          height > 17))
    );
  };

  toggleParcelSelectBanner = () => {
    this.setState({
      togglePSBannerExpand: !this.state.togglePSBannerExpand
    });
  };

  setHazmatType = (e) => {
    const target = e.target;
    this.setState((prevState) => {
      return {
        hazmatType: target.value,
        hazmatDescription: target.innerHTML,
        errors: {
          ...prevState.errors,
          hazmat: ""
        }
      };
    });
  };

  resetHazmatType = () => {
    this.setState({
      hazmatType: "0",
      hazmatDescription: "Select"
    });
  };

  setHazmatYesNo = (value) => {
    this.setState((prevState) => {
      return {
        hazmatYesNo: value,
        hazmatType: value ? prevState.hazmatType : null,
        errors: {
          ...prevState.errors,
          hazmatRadio: ""
        }
      };
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          id="sunsetBannerContainer"
          style={{
            position: "relative",
            paddingTop: "20px",
            height: "70px",
            maxWidth: "1170px",
            minWidth: "242px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <div
            style={{
              backgroundColor: "#3573b1",
              justifyContent: "left",
              position: "absolute",
              left: "0",
              width: "2%",
              height: "65%"
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#EDEDED",
              justifyContent: "center",
              textAlign: "left",
              fontSize: "14px",
              padding: "10px",
              position: "absolute",
              right: "0",
              width: "98%",
              height: "65%"
            }}
          >
            
            <div
              sytle={{
                width: "98%",
                height: "100%"
              }}>
              <strong
                style={{
                  fontSize: "15px",
                  color: "#333366",
                  width: "100%"
                }}
              >
                Important:{" "}
              </strong>{" "}  
              This version of Click-N-Ship<sup>&reg;</sup>{" "} will be retired on February 26, 2025, at 11:59PM EDT.{" "}
              <a
              href="https://faq.usps.com/s/article/Enhanced-Click-N-Ship-Experience-CNSv2?_gl=1*dxyjri*_gcl_au*MTM4NTIzMjI3NS4xNzExMTE3MjQw*_ga*MTkyMzYyOTc3Ny4xNzExMTE3MjQw*_ga_3NXP3C8S9V*MTcxMzQ2MDM2Ny44LjEuMTcxMzQ2MDYyNy4wLjAuMA..#LegacySunset"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#3366CC"
              }}
              >
              Learn what to expect.
            </a>
            </div>
          </div>
        </div>
        <BannerCarousel
          lookupCodes={this.props.lookupCodes}
          showConnectLocalSelfEnrollmentBanner={
            this.props.showConnectLocalSelfEnrollmentBanner
          }
          showConnectLocalSelfEnrollmentPlaceholder={
            this.props.showConnectLocalSelfEnrollmentPlaceholder
          }
          successSignup={this.props.successSignup}
          refreshUserInfo={this.props.refreshUserInfo}
          toggleSpinner={this.props.toggleSpinner}
          showRedirectToNewCnsBanner={this.props.showRedirectToNewCnsBanner}
          showHidePostagePriceBanner={this.props.showHidePostagePriceBanner}
          showHolidaySurchargeBanner={this.props.showHolidaySurchargeBanner}
          showNewV2ServiceBanner={this.props.showNewV2ServiceBanner}
          showlastLPAPersonal={this.props.showlastLPAPersonal}
          showlastLPABusiness={this.props.showlastLPABusiness}
        />
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.configLabelInfoisLoaded &&
        this.state.fetchLabelInfoIsLoaded ? (
          <React.Fragment>
            <div
              className="Step_One_Container"
              id="return-address"
              tabIndex="-1"
            >
              <div className="container-fluid">
                <h2 className="d-md-none responsive-header">Create a Label</h2>
                <h2 className="normal">
                  Step 1: <strong>Return Address</strong>
                </h2>
                <div className="col-12 form-group step-one-click-ship step-one-return-address" />
                <StepOneReturnAddress
                  configInfo={{
                    ...this.state,
                    userData: this.props.userData
                  }}
                  changeReturnAddressField={this.changeReturnAddressField}
                  updateUSAState={this.updateUSAState}
                  changeTrackingNotifications={this.changeTrackingNotifications}
                  changeNonNestedFields={this.changeNonNestedFields}
                  clearError={this.clearError}
                  lookupCodes={this.props.lookupCodes}
                  fetchDropOffLocationsIfNeeded={
                    this.fetchDropOffLocationsIfNeeded
                  }
                />
              </div>
            </div>
            <StepTwoDeliveryAddress
              configInfo={{
                ...this.state,
                userData: this.props.userData
              }}
              changeDeliveryAddress={this.changeDeliveryAddress}
              changeTrackingNotifications={this.changeTrackingNotifications}
              changeNonNestedFields={this.changeNonNestedFields}
              changeHoldForPickup={this.changeHoldForPickup}
              batchChecked={this.state.batchChecked}
              setBatchChecked={this.setBatchChecked}
              addToBatchMessage={this.state.addToBatchMessage}
              setAddToBatchMessage={this.setAddToBatchMessage}
              inBatchEdit={this.state.inBatchEdit}
              setInBatchEdit={this.setInBatchEdit}
              focusOnBatchEdit={this.state.focusOnBatchEdit}
              setFocusOnBatchEdit={this.setFocusOnBatchEdit}
              removeBatchItem={this.removeBatchItem}
              removeAllBatch={this.removeAllBatch}
              focusOnBatchEmpty={this.state.focusOnBatchEmpty}
              setFocusOnBatchEmpty={this.setFocusOnBatchEmpty}
              setInputErrors={this.setInputErrors}
              clearError={this.clearError}
              countryList={this.state.countryList}
              isDomesticDestination={this.isDomesticDestination}
              toggleSpinner={this.props.toggleSpinner}
              cartCount={this.props.cartCount}
              lookupCodes={this.props.lookupCodes}
              fetchDropOffLocationsIfNeeded={this.fetchDropOffLocationsIfNeeded}
            />
            <StepThreeShippingDate
              currentShippingDates={this.state.shippingDates}
              changeNonNestedFields={this.changeNonNestedFields}
              configInfo={{
                ...this.state,
                userData: this.props.userData
              }}
              clearError={this.clearError}
            />
            <StepFourPackageDetails
              configInfo={{
                ...this.state,
                userData: this.props.userData
              }}
              changelabelPkg={this.changelabelPkg}
              changeTrackingNotifications={this.changeTrackingNotifications}
              clearError={this.clearError}
              isWeightRequired={this.isWeightRequired}
              isDomesticDestination={this.isDomesticDestination}
              fetchDropOffLocationsIfNeeded={this.fetchDropOffLocationsIfNeeded}
              isInUspsConnectFlow={this.isInUspsConnectFlow}
              hideNonmachinable={this.hideNonmachinable}
              dropOffLocations={this.state.dropOffLocations}
              selectedDropOffLocationIndex={
                this.state.selectedDropOffLocationIndex
              }
              setHazmatType={this.setHazmatType}
              resetHazmatType={this.resetHazmatType}
              hazmatType={this.state.hazmatType}
              hazmatYesNo={this.state.hazmatYesNo}
              setHazmatYesNo={this.setHazmatYesNo}
              hazmatDescription={this.state.hazmatDescription}
            />
            <StepFiveServiceType
              configInfo={{
                ...this.state,
                userData: this.props.userData
              }}
              setPackageTypeProductId={this.setPackageTypeProductId}
              changeTrackingNotifications={this.changeTrackingNotifications}
              changelabelPkg={this.changelabelPkg}
              updateCartSize={this.props.updateCartSize}
              toggleSpinner={this.props.toggleSpinner}
              changeNonNestedFields={this.changeNonNestedFields}
              setInputErrors={this.setInputErrors}
              clearError={this.clearError}
              isFromCart={this.state.isFromCart}
              isEditLabel={this.state.isEditLabel}
              setIsEditLabel={this.setIsEditLabel}
              batchChecked={this.state.batchChecked}
              addToBatchMessage={this.state.addToBatchMessage}
              setAddToBatchMessage={this.setAddToBatchMessage}
              inBatchEdit={this.state.inBatchEdit}
              lookupCodes={this.props.lookupCodes}
              isWeightRequired={this.isWeightRequired}
              isDomesticDestination={this.isDomesticDestination}
              isInUspsConnectFlow={this.isInUspsConnectFlow}
              dropOffLocations={this.state.dropOffLocations}
              selectedDropOffLocationIndex={
                this.state.selectedDropOffLocationIndex
              }
              dropoffLocationData={this.state.dropoffLocationData}
              updateDropOffLocation={this.updateDropOffLocation}
              hideNonmachinable={this.hideNonmachinable}
              changeReturnAddressField={this.changeReturnAddressField}
              updateUSAState={this.updateUSAState}
              changeDeliveryAddress={this.changeDeliveryAddress}
              userPreferences={this.props.userPreferences}
              setUsingHidePostagePriceAddress={
                this.setUsingHidePostagePriceAddress
              }
              hazmatType={this.state.hazmatType}
              hazmatYesNo={this.state.hazmatYesNo}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default CreateLabel;
